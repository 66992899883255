.container-deleteuser{
  margin-top: 80px;

  p{
    text-align: center;
  }

  .deleteuser-image{
    width: 250px;
    height: auto;
    border: 2px solid var(--green);
    border-radius: 20px;
    margin: 0 auto;
    display: block;
  }
} 

