#loader-overlay {
  position: fixed;
  inset: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;

  .spinner-border {
    width: 100px;
    height: 100px;
    color: $secondary-green;
    font-size: 25px;
  }
}
