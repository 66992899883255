.flex {
  display: flex;
  flex-flow: row wrap;

  &.flex-reverse {
    flex-flow: row wrap-reverse;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.justify-space-between {
    justify-content: space-between;
  }

  &.align-center {
    align-items: center;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-stretch {
    align-items: stretch;
  }
}

.direction-row {
  flex-direction: row;
}

.direction-column {
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.no-wrap {
  flex-wrap: nowrap;
}

.full-width {
  width: 100%;
}
