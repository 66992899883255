#main-carousel{

  .carousel-item{
    width: 100%;
    height: 550px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .button-wrapper{
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
  }
}