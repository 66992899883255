#home-introduction{
  border-top: $gold 2px solid;
  width: 100%;
  height: 600px;
  background-image: url('../../../public/images/introduction.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  .introduction-text{
    width: 80%;
    padding: 50px;
    border-radius: 30px;
    background: rgba($color: #000000, $alpha: .6);
    text-align: center;

    h1{
      margin-bottom: 50px;
    }
  }
}

@media (max-width: 998px){
  #home-introduction{
    height: auto;
  }
}