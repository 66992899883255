.tabs-container {
  overflow-x: auto; 
  white-space: nowrap; 

  #tab-cgp {
    display: flex; 
    flex-wrap: nowrap; 
  
    .nav-link{
      color: #000;
  
      &.active{
        font-weight: bold;
      }
    }
  }
  
  .nav-item {
    flex-shrink: 0; /* Prevent shrinking of the items */
  }
}


