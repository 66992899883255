.cgp-button-wrapper {
  text-decoration: none;

  .cgp-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    background-color: $primary-green;
    border-radius: 12px;

    &:hover {
      background-color: $secondary-green;
    }

    .cgp-button-text {
      color: white;
    }
  }
}